import {ref} from "vue"
import {defineStore} from 'pinia'
import {ResponseSharedPage, User} from "~/interface";
import pkg from "lodash";
import {useUserStore} from "~/stores/user";

const {cloneDeep, isEqual} = pkg
const DEFAULT = {
  instance: undefined,
  links: {next: '', previous: ''},
  num_pages: 0,
  page_size: 0,
  properties: [],
  count: 0,
  results: []
}
export const useHomeStore = defineStore('home', () => {
  const userStore = useUserStore()
  const r1 = ref<ResponseSharedPage>(cloneDeep(DEFAULT))
  const r2 = ref<ResponseSharedPage>(cloneDeep(DEFAULT))
  const r3 = ref<ResponseSharedPage>(cloneDeep(DEFAULT))
  const r4 = ref<ResponseSharedPage>(cloneDeep(DEFAULT))
  const fetched = ref(false)
  const setData = (rsp: (ResponseSharedPage | null | undefined) []) => {
    r1.value = rsp[0] ? rsp[0] : cloneDeep(DEFAULT)
    r2.value = rsp[1] ? rsp[1] : cloneDeep(DEFAULT)
    r3.value = rsp[2] ? rsp[2] : cloneDeep(DEFAULT)
    r4.value = rsp[3] ? rsp[3] : cloneDeep(DEFAULT)
    fetched.value = true
  }

  watch(() => fetched.value, () => {
    if (userStore.isLogged && fetched.value && process.client) {
      useAuthFetch('/activity/check-votes', {
        method: "POST",
        body: {
          target_content_type: 9,
          target_object_ids: r1.value.results.map(x => x.id)
            .concat(r2.value.results.map(x => x.id))
            .concat(r3.value.results.map(x => x.id))
            .concat(r4.value.results.map(x => x.id))
        }
      }).then((r) => {
        const res: string[] = r.data.value as string[]
        r1.value.results.forEach(x => {
          if (res.includes(x.id.toString())) {
            x.is_vote = true
          }
        })
        r2.value.results.forEach(x => {
          if (res.includes(x.id.toString())) {
            x.is_vote = true
          }
        })
        r3.value.results.forEach(x => {
          if (res.includes(x.id.toString())) {
            x.is_vote = true
          }
        })
        r4.value.results.forEach(x => {
          if (res.includes(x.id.toString())) {
            x.is_vote = true
          }
        })
      })
    }
  })

  return {
    r1, r2, r3, r4,
    fetched,
    setData
  }
})

