import revive_payload_client_G0TCP5zLpc from "/home/pixelartmaker_web/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_i4srajujlmuety5gqtwhebphyi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_2H85fpHoXH from "/home/pixelartmaker_web/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_i4srajujlmuety5gqtwhebphyi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8N0vYCiUpd from "/home/pixelartmaker_web/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_i4srajujlmuety5gqtwhebphyi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_yfZQI0Savh from "/home/pixelartmaker_web/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_i4srajujlmuety5gqtwhebphyi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_TEEAzi3kFg from "/home/pixelartmaker_web/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_i4srajujlmuety5gqtwhebphyi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_aH7pAM4zCQ from "/home/pixelartmaker_web/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_i4srajujlmuety5gqtwhebphyi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_PyVisWRNKl from "/home/pixelartmaker_web/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.1_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/pixelartmaker_web/.nuxt/components.plugin.mjs";
import prefetch_client_yut90P7RFH from "/home/pixelartmaker_web/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_i4srajujlmuety5gqtwhebphyi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_sCHeRad9sB from "/home/pixelartmaker_web/node_modules/.pnpm/nuxt-lazy-load@3.0.4_magicast@0.3.5_rollup@4.21.1/node_modules/nuxt-lazy-load/dist/runtime/plugin.mjs";
import templates_pwa_client_3b367a74_BPIBEMKr6W from "/home/pixelartmaker_web/.nuxt/templates.pwa.client.3b367a74.ts";
import plugin_client_0Rx3i6elYl from "/home/pixelartmaker_web/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.5_rollup@4.21.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/home/pixelartmaker_web/.nuxt/unocss.mjs";
import auth_vT9JWWT9pN from "/home/pixelartmaker_web/plugins/auth.ts";
import socialShare_client_u4XrwCs4bM from "/home/pixelartmaker_web/plugins/socialShare.client.ts";
import touch_client_hyaPpzYrWA from "/home/pixelartmaker_web/plugins/touch.client.ts";
import touch_5RFx4YnLk6 from "/home/pixelartmaker_web/plugins/touch.ts";
import utilities_Hys3Y2ToiA from "/home/pixelartmaker_web/plugins/utilities.ts";
export default [
  revive_payload_client_G0TCP5zLpc,
  unhead_2H85fpHoXH,
  router_8N0vYCiUpd,
  navigation_repaint_client_yfZQI0Savh,
  check_outdated_build_client_TEEAzi3kFg,
  chunk_reload_client_aH7pAM4zCQ,
  plugin_vue3_PyVisWRNKl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_yut90P7RFH,
  plugin_sCHeRad9sB,
  templates_pwa_client_3b367a74_BPIBEMKr6W,
  plugin_client_0Rx3i6elYl,
  unocss_MzCDxu9LMj,
  auth_vT9JWWT9pN,
  socialShare_client_u4XrwCs4bM,
  touch_client_hyaPpzYrWA,
  touch_5RFx4YnLk6,
  utilities_Hys3Y2ToiA
]