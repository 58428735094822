import { default as about2dk5ApQh89Meta } from "/home/pixelartmaker_web/pages/about.vue?macro=true";
import { default as _91id_string_93TnfubQjTYaMeta } from "/home/pixelartmaker_web/pages/art/[id_string].vue?macro=true";
import { default as _91id_string_93goqLhQ0A3fMeta } from "/home/pixelartmaker_web/pages/arts/[id_string].vue?macro=true";
import { default as indexDYKxgNTeK1Meta } from "/home/pixelartmaker_web/pages/arts/index.vue?macro=true";
import { default as _91id_string_93I9hhGFEs2VMeta } from "/home/pixelartmaker_web/pages/creator/[id_string].vue?macro=true";
import { default as indexaxr1WWWyqkMeta } from "/home/pixelartmaker_web/pages/creator/index.vue?macro=true";
import { default as editorWwp6shasx1Meta } from "/home/pixelartmaker_web/pages/editor.vue?macro=true";
import { default as helloRXXj0e2e82Meta } from "/home/pixelartmaker_web/pages/hello.vue?macro=true";
import { default as index0osG0wB4vuMeta } from "/home/pixelartmaker_web/pages/index.vue?macro=true";
import { default as _91collection_93FQHCS5CeEtMeta } from "/home/pixelartmaker_web/pages/work/[collection].vue?macro=true";
import { default as indexkiQoqnfxa1Meta } from "/home/pixelartmaker_web/pages/work/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/home/pixelartmaker_web/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "art-id_string",
    path: "/art/:id_string()",
    component: () => import("/home/pixelartmaker_web/pages/art/[id_string].vue").then(m => m.default || m)
  },
  {
    name: "arts-id_string",
    path: "/arts/:id_string()",
    component: () => import("/home/pixelartmaker_web/pages/arts/[id_string].vue").then(m => m.default || m)
  },
  {
    name: "arts",
    path: "/arts",
    component: () => import("/home/pixelartmaker_web/pages/arts/index.vue").then(m => m.default || m)
  },
  {
    name: "creator-id_string",
    path: "/creator/:id_string()",
    component: () => import("/home/pixelartmaker_web/pages/creator/[id_string].vue").then(m => m.default || m)
  },
  {
    name: "creator",
    path: "/creator",
    component: () => import("/home/pixelartmaker_web/pages/creator/index.vue").then(m => m.default || m)
  },
  {
    name: "editor",
    path: "/editor",
    component: () => import("/home/pixelartmaker_web/pages/editor.vue").then(m => m.default || m)
  },
  {
    name: "hello",
    path: "/hello",
    component: () => import("/home/pixelartmaker_web/pages/hello.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/pixelartmaker_web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "work-collection",
    path: "/work/:collection()",
    component: () => import("/home/pixelartmaker_web/pages/work/[collection].vue").then(m => m.default || m)
  },
  {
    name: "work",
    path: "/work",
    component: () => import("/home/pixelartmaker_web/pages/work/index.vue").then(m => m.default || m)
  }
]